#header {
    margin-left: auto;
    margin-right: auto;

    position: sticky;
    z-index: 10;
    top: 0;
    padding: 1.2em 0;
    max-height: 4em;
    justify-content: center;

    background: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.11);
    color: #34495E;

    font-weight: 400;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
}

#header span {
    font-size: 18px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease;
    margin: 1em;
    padding-bottom: 0.3em;
}

#header span:hover {
    cursor: pointer;
    border-bottom: 1px solid black;
}

.code_block {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #33b38d;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1em;
    max-width: 100%;
    overflow: auto;
    padding: 0.3em 1em;
    display: block;
    word-wrap: break-word;
    text-align: left;
}
