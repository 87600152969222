.linkCV a {
    color: #34495E;
    text-decoration: none;
    text-align: right;
}

nav {
    margin-left: auto;
    margin-right: auto;

    position: sticky;
    z-index: 10;
    top: 0;
    padding: 1.2em 0;
    max-height: 4em;
    justify-content: center;

    background: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.11);
    color: #34495E;

    font-weight: 400;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
}

.navSubElement {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

nav span {
    font-size: 18px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease;
    margin: 1em;
    padding-bottom: 0.3em;
}

nav span:hover {
    cursor: pointer;
    border-bottom: 1px solid black;
}

nav span:hover i {
    color: black;
}

nav span.activeClass i {
    color: black;
}

nav span.activeClass {
    border-bottom-color: black;
}