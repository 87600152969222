.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-content {
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 15px;
}
.exit-icon {
    cursor: pointer;
    font-size: 25px;
}
.modal-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
}
